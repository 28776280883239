import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import Layout from "root/components/Layout";
// import BlogPostPreview from "root/components/BlogPostPreview";
// import SEO from "root/components/SEO";
// import Navbar from "root/components/Navbar";
import withTrans from "root/i18n/withTrans";
import NotFoundPage from "root/pages/404";

// import styles from "./blog.module.css";

export const query = graphql`
  query {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          frontmatter {
            path
            author {
              childAuthorsJson {
                name
                position
              }
            }
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              image: childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
/* eslint-disable id-length */

function Blog(/* {
  data: {
    allMdx: { edges },
  },
  t,
} */) {
  return <NotFoundPage />;
  /*
  return (
    <div>
      <SEO title="Sioslife" description="" keywords="" />
      <Layout title="Blog | Sioslife" description="Sioslife's Blog">
        <Navbar currentPage="blog" translations={t("pages")} />
        <div className={styles.root}>
          {edges.map(({ node }) => {
            const author = node.frontmatter.author.childAuthorsJson;

            return (
              <div key={node.frontmatter.path} className={styles.preview}>
                <BlogPostPreview {...node.frontmatter} author={author} />
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
  */
}

Blog.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({ edges: PropTypes.arrayOf(PropTypes.any) }),
  }).isRequired,
  // t: PropTypes.func.isRequired,
};
/* eslint-enable id-length */

export default withTrans(Blog);
